<template>
  <div>
    <v-simple-table class="table mt-2" height="81vh">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Promotion Code</th>
            <th class="text-left">Type</th>
            <th class="text-left">Redemptions</th>
            <th class="text-left"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in vouchers" :key="i">
            <td>{{ item.code }}</td>
            <td>
              <p v-if="item.discount">
                <b>{{ item.discount }}% discount on entire cart</b>
              </p>
              <p v-if="item.delivery">
                Discount on delivery up to<b
                  >RM {{ parseFloat(item.delivery).toFixed(2) }}</b
                >
              </p>
              <p v-if="item.min">
                Minimum spending of
                <b>RM {{ parseFloat(item.min).toFixed(2) }} required</b>
              </p>
            </td>
            <td>{{ item.redemption }}</td>
            <td>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="blue" v-bind="attrs" v-on="on">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="error" v-bind="attrs" v-on="on">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Remove</span>
              </v-tooltip>
              <!-- <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="green" v-bind="attrs" v-on="on">
                      <v-icon>mdi-account-arrow-left</v-icon>
                    </v-btn>
                  </template>
                  <span>Impersonate</span>
                </v-tooltip> -->
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Vouchers",
  data() {
    return {};
  },

  computed: {
    ...mapGetters({
      vouchers: "getVouchers",
    }),
  },

  beforeMount() {
    this.$store.dispatch("startLoad");
    this.$store.dispatch("fetchVouchers");
  },
};
</script>
<style lang="scss" scoped>
.table {
  overflow-y: auto;
}
</style>
